<template>
    <div>
        <page-header :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <form v-if="mailing.value">
                            <div class="form-group col-12 mb-5">
                                <label for="defaultEmail">
                                    Default email:
                                </label>
                                <input
                                    id="title"
                                    v-model="mailing.value.default"
                                    type="text"
                                    class="form-control"
                                    :class="{
                                        'is-invalid':
                                            $v.mailing.value.default.$error
                                    }"
                                />
                            </div>
                            <div class="form-group col-12 mb-5">
                                <h5 class="mb-3 text-uppercase">
                                    SetUp training products
                                </h5>
                                <div v-if="mailing.value.products">
                                    <div
                                        v-for="product in products"
                                        :key="product.id"
                                    >
                                        <div class="form-group mb-4">
                                            <h5>{{ product.name }}</h5>
                                            <label for="productEmails">
                                                Emails:
                                            </label>
                                            <textarea
                                                v-model="
                                                    mailing.value.products[
                                                        product.name
                                                    ]
                                                "
                                                :class="{
                                                    'is-invalid':
                                                        areEmailsValid(product)
                                                }"
                                                type="text"
                                                class="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-12 mb-5">
                                <h5 class="mb-2 text-uppercase">
                                    Signup Request
                                </h5>
                                <label for="signupRequest"> Emails: </label>
                                <textarea
                                    id="signupRequest"
                                    v-model="mailing.value.signup_request"
                                    :class="{
                                        'is-invalid':
                                            $v.mailing.value.signup_request
                                                .$error
                                    }"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                            <div
                                v-if="currentUser.role === 'admin'"
                                class="form-group col-12 mb-5"
                            >
                                <h5 class="mb-2 text-uppercase">
                                    OffLabel Product Request
                                </h5>
                                <label for="offlabelRequest"> Emails: </label>
                                <textarea
                                    id="offlabelRequest"
                                    v-model="mailing.value.offlabel_request"
                                    :class="{
                                        'is-invalid':
                                            $v.mailing.value.offlabel_request
                                                .$error
                                    }"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                            <div
                                class="form-group col-12 d-flex justify-content-end"
                            >
                                <button
                                    type="button"
                                    class="btn btn-success waves-effect waves-light"
                                    @click="save"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                    <!-- end card-body-->
                </div>
                <!-- end card-->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import config from '@config';

function containEmails(value) {
    if (value === '' || value === undefined) {
        return true;
    }
    return /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(
        value
    );
}

export default {
    page: {
        title: 'Mailing',
        meta: [{ name: 'description', content: config.description }]
    },

    validations: {
        mailing: {
            value: {
                default: {
                    required,
                    containEmails
                },
                offlabel_request: {
                    containEmails
                },
                signup_request: {
                    containEmails
                },
                products: {
                    $each: {
                        containEmails
                    }
                }
            }
        }
    },

    data() {
        return {
            title: 'Mailing Management',
            items: [
                {
                    text: 'NovoCare',
                    to: '/'
                },
                {
                    text: 'Mailing',
                    active: true
                }
            ],
            mailing: {},
            products: [],
            selectedProduct: null,
            selectedContactProduct: null
        };
    },

    computed: {
        currentUser() {
            return this.$store.getters['auth/currentUser'];
        }
    },

    async mounted() {
        await this.fetchProducts();
        await this.fetchSettings();
    },

    methods: {
        ...mapActions({
            getProducts: 'products/index',
            getSetting: 'settings/getSettingByKey',
            updateSetting: 'settings/updateSettingsByTags'
        }),

        async save() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const settings = [];
            const mails = this.mailing;
            settings.push(mails);

            try {
                await this.updateSetting({
                    tag: 'mailing',
                    settings
                });

                this.fetchSettings();

                this.$v.$reset();

                this.$bvToast.toast('Emails have been updated', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                let message = '';

                if (err.response.data.errors) {
                    err.response.data.errors.forEach(error => {
                        message += error + '. ';
                    });
                } else {
                    message = 'Something went wrong!';
                }

                this.$bvToast.toast(message, {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async fetchProducts() {
            const response = await this.getProducts();

            this.products = response.filter(
                product => product.in_setup_training
            );
            this.selectedProduct = this.products[0].name;
            this.selectedContactProduct = this.products[0].name;
        },

        async fetchSettings() {
            try {
                const response = await this.getSetting({
                    key: 'mailing_list'
                });

                this.mailing = { ...response };
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        areEmailsValid(product) {
            return (
                this.$v.mailing.value.products.$each.$iter[product.name] &&
                !this.$v.mailing.value.products.$each.$iter[product.name]
                    .containEmails
            );
        }
    }
};
</script>
